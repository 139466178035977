body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Reset CSS
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

//Source Sans Pro font style
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap');

// Custom css 
body {
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

// display 
.inline {
  display: inline;
}
.block {
  display: block;
}
.inline-block {
  display: inline-block;
}
// flex 
.flex {
  display: flex;
  justify-content: space-between;
}
.flex-start {
  justify-content: flex-start;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: flex-start;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.space-around {
  justify-content: space-around;
}
.end {
  justify-content: end;
}
.align-end {
  align-items: flex-end;
}
.column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-50 {
  flex: 0 1 50%;
}

// font
h1 {
  font-size: 40px;
  font-weight: bolder;
}
h2 {
  font-size: 32px;
  font-weight: bolder;
}

h3 {
  font-size: 24px;
  font-weight: bolder;
}

h4 {
  font-size: 18px;
  font-weight: bolder;
}
h5 {
  font-size: 16px;
  font-weight: bolder;
}
p {
  font-size: 14px;
}

.font-bolder {
  font-weight: 600;
}

// positions 
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

// border 
.brd-radius {
  border-radius: 4px;
}
// others 
.cursor-pr{
  cursor: pointer;
}


.container {
  box-sizing: border-box;
  max-height: 100vh;
  max-width: 100%;
  .info-container {
    background: transparent linear-gradient(139deg, #34A853 0%, #249C54 100%) 0% 0% no-repeat padding-box;
    height: 100vh;
    .info-content {
      max-width: 60%;
      margin-top: 60px;
      .comments-header {
        margin: 40px 0 50px 0;
        font-size: 30px;
        color: #FFFFFF;
        font-size: 37px;
      }

      .slideshow {
        overflow: hidden;
        max-width: 100%;
      }
      
      .slideshowSlider {
        white-space: nowrap;
        display: flex;
        overflow-x: auto;
        width: 300%;
      }
      
      .slide {
        display: inline-block;
        width: 100%;
        font-size: 22px;
        color: #FFFFFF;
        white-space: normal;
        font-size: 24px;
        letter-spacing: 0px;
        line-height: 32px;
        padding-right: 10px;
      }
      .dots {
        display: flex;
        margin-top: 20px;
        .dot {
          margin-right: 10px;
          width: 10px;
          height: 10px;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }
        .active-dot {
          width: 40px;
        }
      }
      .slide-anim {
        -webkit-transition:all 2.0s ease-in-out;
        -moz-transition:all 2.0s ease-in-out;
        -o-transition:all 2.0s ease-in-out;
        transition:all 2.0s ease-in-out;
      }
    }
    .path-img1 {
      top: -15%;
      right: -10%;
    }
    .path-img2 {
      bottom: -36%;
      left: -25%;
    }
  }
  .sign-in-form {
    padding: 12% 14% 0% 14%;
    input[type=text],input[type=password], input[type=number]{
      height: 45px;
      padding: 0 20px;
      margin-top: 5px;
      border: 1px solid rgba(222, 226, 230, 1);
      border-radius: 4px;
      &::placeholder {
        font-size:16px;
        letter-spacing: 0px;
        color: #000000;
      }
      font-size:16px;
      letter-spacing: 0px;
      color: #000000;
    }
    input[type=checkbox]{
      width: 25px;
      height: 25px;
      background: #249C54;
      margin-right: 10px;
    }
    .action-btn {
      height: 45px;
      background: #249C54;
      width: 100%;
      border: none;
      color: #FFFFFF;
      &:hover {
       background: #146d38; 
      }
    }
    .email-link {
      text-decoration: none;
      color: #249C54;
    }
    .errors {
      color: red;
      text-align: center;
    }
    .limetrayLogo {
      max-width: 200px;
    }
    .show-button {
      position: absolute;
      right: 30px;
      top: 42px;
      color: #787878;
      border: 0;
      background: none;
      font-size: 16px;
    }
  }
  .lost-device-button {
    border: none;
    background: transparent;
    color: #2081D4;
    padding-top: 8px;
  }
  .margin-20 {
    margin: 20px 0;
  }
  .margin-top-24 {
    margin-top: 24px;
  }
  .font-16 {
    font-size: 16px;
    line-height: 24px;
  }
  .margin-top-20 {
    margin-top: 20px;
  }
  .rotate-180 {
    transform: rotate(180deg);
  }
  .text-align-end {
    text-align: end;
  }
}

@media only screen and (max-width: 768px) {
  .sm-display-none {
    display: none;
  }
  .sm-flex-100 {
    flex: 0 1 100%;
  }
  .container .sign-in-form {
    padding: 60px 20px;
  }
  .sm-font-35 {
    font-size: 35px;
  }
  .sm-text-center {
    text-align: center;
  }
}
